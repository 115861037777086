import 'jquery';
import 'lity';
import Glide from '@glidejs/glide';

jQuery(function($) {
	$('#header nav li.menu-item-has-children').click(function(e) {
		if(!$(this).hasClass('open') && $(window).width() < 992) {
			e.preventDefault();
			$(this).parent().find('.open ul').slideUp();
			$(this).parent().find('.open').removeClass('open');
			$(this).addClass('open').find('ul').slideDown();
		}
	});

	$('#header #menu-toggle').click(function() {
		$(this).toggleClass('open');
		$('#header nav .open').removeClass('open');
		$('#header nav').stop().slideToggle();
	});

	$('.woocommerce-MyAccount-navigation li.is-active').click(function(e) {
		if($('li.is-active').is(e.target)) {
			$(this).parent().toggleClass('open');
		}
	});

	$('.block-on_the_farm button[data-tab]').click(function() {
		var tab = $(this).attr('data-tab');
		$(this).parent().find('button.active').removeClass('active');
		$(this).addClass('active');
		$(this).parents().eq(3).find('div.tab.active').removeClass('active');
		$(this).parents().eq(3).find('div.tab[data-tab='+tab+']').addClass('active');
	});

	$('.block-faqs .faq button').click(function() {
		$(this).toggleClass('open').parent().find('div').stop().slideToggle(250);
	});

	if($('.glide-gallery').length) {
		new Glide('.glide-gallery', {
			perView: 4,
			gap: 20,
			type: 'carousel',
			breakpoints: {
				992: {perView: 3},
				768: {perView: 2},
				576: {perView: 1}
			}
		}).mount();
	}

	if($('.glide-team').length) {
		new Glide('.glide-team', {
			perView: 3,
			gap: 60,
			type: 'carousel',
			breakpoints: {
				992: {perView: 2, gap: 40},
				768: {perView: 1}
			}
		}).mount();
	}

	if($('.glide-slideshow').length) {
		new Glide('.glide-slideshow', {
			perView: 1,
			type: 'carousel',
		}).mount();
	}

	if(!document.cookie.includes('popup_shown')) {
		setTimeout(function () {
			$('#sign-up-popup').stop().fadeIn();
		}, 8000);
	}

	$('#sign-up-popup-close').click(function() {
		$('#sign-up-popup').stop().fadeOut();

		let date = new Date();
		date.setDate(date.getDate() + 30);
		let expires = "expires=" + date.toUTCString();

		document.cookie = 'popup_shown=1;' + expires + '; path=/;';
	})
});